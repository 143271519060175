@import "../config";

.dashBg {
  background-image: url(../../assets/img/dashboardBg.jpg);
  background-size: cover;
}

.titleContainer {
  margin-bottom: 1rem;
  border-left: thick solid $app-red;
  padding-left: 20px;
}

.userPopover {
  max-width: 220px;
  font-family: 'Poppins';
  text-align: center;
  padding-bottom: 10px;
  
  .info {
    padding: 20px 20px 0 20px;

    h3{ color: $app-red; }
  
    h4 { margin-top: 5px; }
  }

  nav a {
    color: $app-title-gray;

    &:hover { color: $app-light-gray }
  }

  //logout button
  & > a button {
    border: 2px solid;
    font-weight: 600;
    
    &:hover {
      border: 2px solid;

    }
  }
}

#appBarSpacer {
  min-height: 55px;
}

// @include lg {

// }

@media print {
  @page {
    size: auto;
  }
  main {
    height: unset !important;
    padding: unset !important;
  }

  #atacanaAppBar,
  #appBarSpacer,
  #atacanaDrawer,
  #download,
  .actionBtn,
  .no-print,
  .reportSelect,
  #footnotes-check,
  #footnotes-viewtype-toggle,
  hr {
    display: none !important;
  }

  #printMarginFix {
    margin-left: 0 !important;
  }

  body * {
    visibility: hidden !important;
  }

  #toImage,
  .titleContainer,
  .titleContainer *,
  #toImage * {
    visibility: visible !important;
  }
}
